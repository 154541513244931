@import "../../assets/styles/color";

//--------------------

.page-login
{
    width: 100%;
    min-height: calc(100vh - 48px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logo
    {
        max-width: 100%;
    }

    .title
    {
        color: $colorWhite01;
        text-align: center;
        margin-top: 10px;
        opacity: 0.5;
        position: relative;
    }

    .form
    {
        width: 100%;
        margin-top: 48px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        gap: 12px;

        .btn-box
        {
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: space-between;
            flex-direction: row;
            gap: 0.75rem;

            button
            {
                width: 50%;

            }
        }

        //.btn
        //{
        //    &.full
        //    {
        //        width: 100%;
        //    }
        //}
    }

    .links
    {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 48px;

        a
        {
            color: $colorWhite01;
            font-size: 14px;

            &:hover
            {
                color: $colorYellow01;
            }
        }
    }
}
