@import "../../assets/styles/color";

//--------------------

.page-device-add-edit
{
    width: 100%;
    min-height: calc(100vh - 128px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .form
    {
        width: 100%;
        //margin-top: 48px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        gap: 12px;

        .btn
        {
            &.full
            {
                width: 100%;
            }
        }
    }
}
