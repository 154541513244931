@import '../../assets/styles/color';

//---------------------------------------------------------

.page-sms
{
    .btn-codes
    {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        gap: 12px 12px;
        margin-top: 48px;

        .code-box
        {
            background-image: radial-gradient($colorBlack05, $colorBlack06);
            background-color: $colorWhite04;
            width: calc(50% - 6px);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 16px;
            border-radius: 8px;
            box-sizing: border-box;

            &:hover
            {
                background-color: $colorYellow02;
            }

            .btn-box
            {
                background-color: $colorBlack06;
                width: 100%;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border: 3px solid $colorBlack07;
                border-radius: 100px;

                .btn-on, .btn-off, .btn-single
                {
                    background-color: $colorBlack08;
                    width: calc(100% - 4px);
                    height: calc(100% - 4px);
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    color: $colorWhite01;
                    font-size: 18px;
                    border: 2px solid $colorBlack09;
                    border-radius: 50px;
                    cursor: pointer;
                }

                .btn-on
                {
                    width: calc(50% - 4px);
                    color: $colorGreen01;
                    border-color: $colorGreen01;
                    border-left-color: #2b3b42;
                    border-radius: 5px 50px 50px 5px;
                }

                .btn-off
                {
                    width: calc(50% - 4px);
                    color: $colorRed02;
                    border-color: $colorRed02;
                    border-right-color: #505c64;
                    border-radius: 50px 5px 5px 50px;
                }
            }

            .title
            {
                line-height: 12px;
                color: $colorWhite02;
                font-size: 12px;
                margin-top: 8px;
            }
        }
    }
}