@import "../../assets/styles/color";

//--------------------

.page-point-distance
{
    .current-device
    {
        .btn-change
        {
            display: none;
        }
    }

    .form
    {
        width: 100%;
        margin-top: 48px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        gap: 12px;

        .btn
        {
            &.full
            {
                width: 100%;
            }
        }
    }
}
