@import "../../assets/styles/color";

//--------------------

.page-reset-device
{
    .des-btns
    {
        width: 100%;
        max-width: 320px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 24px;
        margin: 24px auto 0 auto;

        .des
        {
            color: $colorWhite01;
            text-align: justify;
            font-size: 14px;
        }

        .btns-box
        {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            gap: 12px;

            .btn
            {
                width: calc(50% - 6px);
                //font-size: 14px;
                text-align: center;
            }
        }
    }
}
