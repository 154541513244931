
$colorBlack01: #444444;
$colorBlack02: rgba(68, 68, 68, 0.3);
$colorBlack03: rgba(68, 68, 68, 0.8);
$colorBlack04: rgba(68, 68, 68, 0.6);
$colorBlack05: #4f6875;
$colorBlack06: #263238;
$colorBlack07: #546E7A;
$colorBlack08: #37474F;
$colorBlack09: #505c64;
$colorBlack10: rgba(68, 68, 68, 0.05);
$colorBlack11: rgba(68, 68, 68, 0.1);

$colorWhite01: #ffffff;
$colorWhite02: rgba(255, 255, 255, 0.7);
$colorWhite03: rgba(255, 255, 255, 0.85);
$colorWhite04: rgba(255, 255, 255, 0.15);


$colorRed01: #ff2c2c;
$colorRed02: #f44336;

$colorPurple01: #743599;

$colorYellow01: #ffcd00;
$colorYellow02: rgba(255, 205, 0, 0.15);

$colorGreen01: #66BB6A;


/*******************/




//$colorWhite: #ffffff;
////$colorWhite01: rgba(255, 255, 255, 0.2);
//$colorWhite02: rgba(255, 255, 255, 0.1);
//$colorWhite03: rgba(255, 255, 255, 0.5);
//$colorWhite04: rgba(255, 255, 255, 0.25);
//
//$colorRed02: #d92424;
//$colorRed03: rgba(252, 63, 63, 0.03);
//
//$colorBlack01: #444444;
//$colorBlack02: rgba(68, 68, 68, 0.3);
//$colorBlack06: rgba(9, 18, 34, 0.9);
//$colorBlack03: rgba(24, 25, 27, 0.4);
//$colorBlack05: rgba(0, 0, 0, 0.4);
//$colorBlack04: rgba(127, 127, 127, 0.5);
//
//$colorNavyBlue01: #374170;
//
//$colorGray01: #F6F8FA;
//$colorGray02: #E8EDF1;
//$colorGray03: rgba(204, 204, 204, 0.4);
//$colorGray04: #DBE3EA;
//$colorGray05: rgba(127, 127, 127, 0.25);
//$colorGray06: #EEF2F5;
//$colorGray07: #E9F1F9;
//$colorGray08: #bbbbbb;
//
//
//$colorGreen01: #139860;




