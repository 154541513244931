/*******************/
@font-face {
  font-family: "IranYekan";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/iran-yekan/iran-yekan-regular.woff") format("woff"), url("../fonts/iran-yekan/iran-yekan-regular.woff2") format("woff2"), url("../fonts/iran-yekan/iran-yekan-regular.ttf") format("truetype");
}
@font-face {
  font-family: "IranYekan";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/iran-yekan/iran-yekan-bold.woff") format("woff"), url("../fonts/iran-yekan/iran-yekan-bold.woff2") format("woff2"), url("../fonts/iran-yekan/iran-yekan-bold.ttf") format("truetype");
}
body {
  background-image: url("../images/bg.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #743599;
  width: 100%;
  min-width: 320px;
  max-width: 640px;
  min-height: 100vh;
  line-height: 2em !important;
  color: #444444;
  font-family: "IranYekan", serif;
  font-weight: 400;
  font-size: 14px;
  padding: 12px;
  margin: 0 auto;
  direction: rtl;
  cursor: default;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.dir-ltr {
  direction: ltr;
}

.dir-rtl {
  direction: rtl;
}

a:hover {
  color: #ffcd00;
}

.page-title {
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 50px;
  position: relative;
}
.page-title:before {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 40%, #ffffff 50%, rgba(255, 255, 255, 0.25) 60%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
}

label.MuiInputLabel-formControl {
  color: rgba(68, 68, 68, 0.8);
  font-size: 14px;
}
label.MuiInputLabel-formControl.Mui-focused {
  color: rgba(68, 68, 68, 0.6) !important;
  font-size: 14px;
}
label.MuiInputLabel-formControl.Mui-error {
  color: #ff2c2c !important;
  font-size: 14px;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiFilledInput-root {
  background-color: #ffffff !important;
}
.MuiFilledInput-root:before {
  border-color: #ffffff !important;
}
.MuiFilledInput-root:after {
  border-color: #ffcd00 !important;
}
.MuiFilledInput-root.Mui-error:before, .MuiFilledInput-root.Mui-error:after {
  border-color: #ff2c2c !important;
}

.t2 .MuiFilledInput-root {
  background-color: rgba(68, 68, 68, 0.05) !important;
}
.t2 .MuiFilledInput-root:before {
  border-color: rgba(68, 68, 68, 0.3) !important;
}

.MuiCheckbox-root {
  color: #ffffff !important;
}

.MuiFormControlLabel-label {
  color: #ffffff !important;
  font-size: 14px !important;
}

.MuiLoadingButton-loadingIndicator {
  color: #ffcd00 !important;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.page-has-header {
  padding-top: 44px;
}

.page-has-footer {
  padding-bottom: 60px;
}

