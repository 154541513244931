@import "../../assets/styles/color";

//--------------------

.page-reset-password
{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;

    .des
    {
        max-width: 320px;
        color: $colorWhite01;
        text-align: justify;
        font-size: 14px;
        margin: 24px auto 0 auto;
    }

    .btn-reset
    {
        max-width: 320px;
    }
}
