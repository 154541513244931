@import "../../assets/styles/color";

//--------------------

.current-device
{
    background-color: $colorYellow02;
    width: max-content;
    color: $colorWhite01;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px 24px;
    margin: 0 auto;
    border-radius: 8px;

    .device-name
    {
        font-weight: 700;
    }

    .btn-change
    {
        .btn-title
        {
            color: $colorYellow01;
            font-size: 12px;
            padding: 0;
        }
    }

    &.t2
    {
        background-color: transparent;
        color: $colorBlack01;
        padding: 0;
        margin: 0;

        .btn-change
        {
            .btn-title
            {
                color: $colorBlack04;
                font-size: 12px;
                padding: 0;
            }
        }
    }
}

.device-menu-items
{
    .item
    {
        min-height: 36px;
        font-size: 14px !important;
    }
}
