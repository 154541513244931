@import "../../assets/styles/color";

//--------------------

.page-setting
{
    .btn-box
    {
        width: 100%;
        max-width: 400px;
        display: flex;
        align-items: start;
        justify-content: start;
        flex-direction: column;
        gap: 12px;
        margin: 24px auto 0 auto;

        a
        {
            background-color: $colorBlack03;
        }
    }
}
