@import "../../assets/styles/color";

//--------------------

.footer-bar
{
    background-color: $colorWhite03 !important;
    height: 48px !important;
    border-radius: 8px 8px 16px 16px;
    box-shadow: 0 4px 10px $colorBlack02;
    position: fixed;
    bottom: 12px;
    right: 12px;
    left: 12px;
    backdrop-filter: blur(3px);

    .MuiSvgIcon-root
    {
        font-size: 20px;
    }

    .MuiBottomNavigationAction-label
    {
        line-height: 15px;
        font-size: 12px !important;
    }

    .MuiButtonBase-root
    {
        background-color: transparent;
        min-width: 70px;
        color: $colorBlack04;
        padding: 0 4px;

        &.Mui-selected
        {
            color: $colorPurple01;
        }
    }
}