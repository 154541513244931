@import '../../assets/styles/color';

//---------------------------------------------------------

.loading-over
{
    background-color: rgba(116, 53, 153, 0.4);
    backdrop-filter: blur(7px);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;

    .loading-box
    {
        display: inline-block;

        div
        {
            background-color: $colorYellow01;
            width: 12px;
            height: 12px;
            display: inline-block;
            border-radius: 100%;
            animation: loading-animate 1.1s infinite ease-in-out both;
        }

        .c1
        {
            animation-delay: -0.32s;
        }

        .c2
        {
            animation-delay: -0.16s;
        }
    }
}

@keyframes loading-animate
{
    0%, 100%, 80%
    {
        transform: scale(0);
    }

    40%
    {
        transform:scale(1);
    }
}