@import "../../assets/styles/color";

//--------------------

.page-install
{
    width: 100%;
    min-height: calc(100vh - 48px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    //padding: 24px;
    //box-sizing: border-box;

    .card-box
    {
        margin-bottom: 24px;

        &:last-child
        {
            margin-bottom: 0;
        }
    }

    .des
    {
        line-height: 2em;
        text-align: justify;
    }

    img
    {
        max-width: 100%;
    }

    .logo
    {
        margin-top: 100px;
    }

    .btn-des
    {
        text-align: center;

        .btn
        {
            color: $colorWhite01;
            border-color: #ffffff;
            margin-top: 16px;

            .MuiButton-icon
            {
                margin: 0 0 0 8px;
            }
        }
    }

    .install-requirement-des
    {
        color: $colorWhite01;
        font-weight: 700;
        text-align: center;
        text-shadow: 0 1px 15px #444444;
    }

    .image-add-2-home
    {
        width: 28px;
    }
}