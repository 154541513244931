@import "../../assets/styles/color";

//--------------------

.page-device-admin
{
    .des
    {
        color: $colorWhite01;
        text-align: justify;
        font-size: 14px;
        margin-top: 24px;
    }

    .form
    {
        width: 100%;
        margin-top: 24px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;

        .btn-box
        {
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: space-between;
            gap: 12px;

            .btn
            {
                width: calc(50% - 6px);

                .icon
                {
                    margin-left: 8px;
                }
            }
        }
    }
}
