@import "../../assets/styles/color";

//--------------------

.page-central-number
{
    .current-device
    {
        .btn-change
        {
            display: none;
        }
    }

    .des
    {
        color: $colorWhite01;
        text-align: justify;
        font-size: 14px;
        margin-top: 24px;
    }

    .form
    {
        width: 100%;
        margin-top: 24px;
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;

        .input-box
        {
            width: 100%;
            display: flex;
            gap: 4px;
        }
    }
}
