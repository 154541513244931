@import '../../assets/styles/color';

//---------------------------------------------------------

.page-devices
{
    .btn-device-add
    {
        color: $colorWhite01;
        font-size: 12px;

        .icon
        {
            font-size: 20px;
            margin-left: 4px;
        }
    }

    .devices-table
    {
        margin-top: 24px;

        th
        {
            font-size: 12px;
            font-weight: 700;
            white-space: nowrap;
        }

        td
        {
            font-size: 14px;
            white-space: nowrap;
        }
    }
}