@import '../../assets/styles/color';

//---------------------------------------------------------

.page-tracking
{
    header
    {
        .btn-box
        {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .btn-filter, .btn-current-position, .btn-layer
        {
            background-color: transparent;
            color: $colorBlack01;
            margin-left: 12px;
            border: 0;
            cursor: pointer;

            .MuiSvgIcon-root
            {
                font-size: 20px;
            }

            &:hover
            {
                color: $colorPurple01;
            }
        }
    }

    .btn-play-pause
    {
        position: absolute;
        bottom: 30px;
        right: 10px;
        z-index: 10000;

        .btn
        {
            background-color: $colorBlack01;
            display: flex;
            padding: 0;
            margin: 0;
            border: 0;
            border-radius: 100%;
            outline: unset;

            .icon
            {
                font-size: 32px;
            }

            &.btn-play
            {
                color: $colorGreen01;
            }
            &.btn-pause
            {
                color: $colorRed02;
            }
        }
    }

    .map-slider
    {
        width: 50%;
        position: absolute;
        bottom: 50px;
        left: 60px;
        z-index: 1000;
    }

    .car-information
    {
        background-color: $colorWhite01;
        padding: 5px 10px;
        border-radius: 10px;
        position: absolute;
        top: 60px;
        left: 20px;
        right: 20px;
        z-index: 10000;

        .item
        {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: start;
            text-align: right;
            direction: rtl;

            .title
            {
                width: 45px;
                font-family: 'IranYekan', serif;
                color: $colorBlack01;
                font-size: 14px;
                font-weight: 700;
            }

            .value
            {
                flex: 1;
                font-family: 'IranYekan', serif;
                color: $colorBlack04;
                font-size: 14px;
                font-weight: 400;
                direction: ltr;
            }
        }
    }
}

.filter-menu-items
{
    .item
    {
        min-height: 36px;
        color: $colorBlack01;
        font-size: 14px !important;
        border-bottom: 1px solid $colorBlack11;

        &:last-child
        {
            border-bottom: 0;
        }

        .MuiCheckbox-root
        {
            color: $colorBlack01 !important;
        }
        .MuiFormControlLabel-label
        {
            color: $colorBlack01 !important;
        }
    }
}

.car-popup
{
    .leaflet-popup-content, .car-popup-inner
    {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        flex-direction: column;
        gap: 0.5rem;
        padding-top: 1rem;
    }
    .car-popup-inner
    {
        padding-top: 0;
    }

    .car-popup-item
    {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        text-align: right;
        direction: rtl;

        .title
        {
            width: 45px;
            font-family: 'IranYekan', serif;
            color: $colorBlack01;
            font-size: 14px;
            font-weight: 700;
        }

        .value
        {
            flex: 1;
            font-family: 'IranYekan', serif;
            color: $colorBlack04;
            font-size: 14px;
            font-weight: 400;
            direction: ltr;
        }
    }
}

.car-popup-start
{
    background-color: $colorGreen01 !important;
    color: $colorWhite01 !important;
    font-family: 'IranYekan', serif;
    font-size: 10px;
    font-weight: 700;
}
.car-popup-end
{
    background-color: $colorRed02 !important;
    color: $colorWhite01 !important;
    font-family: 'IranYekan', serif;
    font-size: 10px;
    font-weight: 700;
}

.dialog-filter-date
{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    .row
    {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}

.leaflet-container
{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.leaflet-bottom.leaflet-right
{
    display: none;
}
.leaflet-top.leaflet-left
{
    top: unset;
    bottom: 30px;
}

.leaflet-marker-icon
{
    &.icon-car
    {
        margin: -5.5px 0 0 -16px;
    }
    &.icon-point
    {
        margin: -5.5px 0 0 -5.5px;
    }
}

.leaflet-pane.leaflet-tooltip-pane
{
    direction: ltr;
}

.icon-direction-sign
{
    .icon-car
    {
        width: 24px;
        height: 24px;
        position: relative;
        top: -6px;
        left: 6px;
    }
}

@for $i from 0 through 360
{
    .icon-direction-sign.t#{$i}
    {
        .icon-car
        {
            transform: rotate(0deg + $i);
            transform-origin: center center;
        }
    }
}