@import "../../assets/styles/color";

//--------------------

.page-server
{
    width: 100%;
    min-height: calc(100vh - 48px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //padding: 24px;
    //box-sizing: border-box;

    .list-servers
    {
        background-color: $colorWhite01;
        width: 100%;
        max-width: 320px;
        box-shadow: 0 4px 10px $colorBlack10;

        .MuiTypography-root
        {
            text-align: center;
        }

        li
        {
            border-bottom: 1px solid $colorBlack11;

            &:last-child
            {
                border-bottom: 0;
            }
        }
    }
}