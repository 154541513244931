@import "../../assets/styles/color";

//--------------------

.page-about
{
    width: 100%;
    //min-height: calc(100vh - 48px);
    text-align: center;

    .logo
    {
        width: 100%;
        max-width: 128px;
        margin-top: 24px;
    }

    .title
    {
        color: $colorWhite01;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
    }

    .des
    {
        color: $colorWhite01;
        text-align: justify;
        font-size: 14px;
        padding: 24px 24px 0 24px;

        .link
        {
            color: $colorWhite01;
            font-size: 16px;

            &:hover
            {
                color: $colorYellow01;
            }
        }
    }

    .support
    {
        color: $colorWhite01;
        text-align: justify;
        font-size: 14px;
        font-weight: 700;
        padding: 24px 24px 0 24px;
    }

    .link-box
    {
        color: $colorWhite01;
        text-align: justify;
        font-size: 14px;
        font-weight: 700;
        padding: 0 24px 0 24px;

        .link
        {
            color: $colorWhite01;

            &:hover
            {
                color: $colorYellow01;
            }
        }
    }
}