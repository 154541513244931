/*******************/
header {
  background-color: rgba(255, 255, 255, 0.85) !important;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  border-radius: 16px 16px 8px 8px;
  box-shadow: 0 4px 10px rgba(68, 68, 68, 0.3);
  position: fixed;
  top: 12px;
  right: 12px;
  left: 12px;
  z-index: 1000;
  backdrop-filter: blur(3px);
}
header .header-title {
  color: #444444;
  font-size: 14px;
}
header .btn-back {
  background-color: transparent;
  color: #444444;
  border: 0;
  cursor: pointer;
}
header .btn-back .MuiSvgIcon-root {
  font-size: 20px;
}
header .btn-back:hover {
  color: #743599;
}

