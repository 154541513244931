// variables
$fontPath: '../fonts/iran-yekan/iran-yekan-';

// font css
@mixin initFont($fontWeight, $fontType)
{
  font-family: 'IranYekan';
  font-style: normal;
  font-weight: $fontWeight;
  src: url($fontPath + $fontType + '.woff') format('woff'),
  url($fontPath + $fontType + '.woff2') format('woff2'),
  url($fontPath + $fontType + '.ttf')  format('truetype');
}

@font-face
{
  @include initFont(400, 'regular')
}
@font-face
{
  @include initFont(700, 'bold')
}
