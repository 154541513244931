@import "../../assets/styles/color";

//--------------------

.dialog-alert
{
	.btn-close
	{
		color: $colorBlack02;
		position: absolute;
		top: 4px;
		left: 4px;
	}

	.dialog-content
	{
		text-align: center;

		.title
		{
			font-size: 16px;
			font-weight: 700;
		}

		.des
		{
			font-size: 14px;
		}

		img
		{
			max-width: 64px;
		}
	}
}
