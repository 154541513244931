@import "../../assets/styles/color";

//--------------------

header
{
    background-color: $colorWhite03 !important;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    border-radius: 16px 16px 8px 8px;
    box-shadow: 0 4px 10px $colorBlack02;
    position: fixed;
    top: 12px;
    right: 12px;
    left: 12px;
    z-index: 1000;
    backdrop-filter: blur(3px);

    .header-title
    {
        color: $colorBlack01;
        font-size: 14px;
    }

    .btn-back
    {
        background-color: transparent;
        color: $colorBlack01;
        border: 0;
        cursor: pointer;

        .MuiSvgIcon-root
        {
            font-size: 20px;
        }

        &:hover
        {
            color: $colorPurple01;
        }
    }
}
