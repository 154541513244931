@import 'color';
@import 'font';

//---------------------------------------------------------

html
{
    //min-height: 100%;
}

body
{
    background-image: url("../images/bg.jpg");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: $colorPurple01;
    width: 100%;
    min-width: 320px;
    max-width: 640px;
    min-height: 100vh;
    line-height: 2em !important;
    color: $colorBlack01;
    font-family: 'IranYekan', serif;
    font-weight: 400;
    font-size: 14px;
    padding: 12px;
    margin: 0 auto;
    direction: rtl;
    cursor: default;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
}

//--------------------

.dir-ltr
{
    direction: ltr;
}
.dir-rtl
{
    direction: rtl;
}

//--------------------

a
{
    &:hover
    {
        color: $colorYellow01;
    }
}

//--------------------

.page-title
{
    width: 100%;
    color: $colorWhite01;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 50px;
    position: relative;

    &:before
    {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 40%, #ffffff 50%, rgba(255, 255, 255, 0.25) 60%, rgba(255, 255, 255, 0) 100%);
        width: 100%;
        height: 1px;
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

//--------------------

label.MuiInputLabel-formControl
{
    color: $colorBlack03;
    font-size: 14px;

    &.Mui-focused
    {
        color: $colorBlack04 !important;
        font-size: 14px;
    }

    &.Mui-error
    {
        color: $colorRed01 !important;
        font-size: 14px;
    }
}

.MuiFormControl-root
{
    width: 100%;
}
.MuiFilledInput-root
{
    background-color: $colorWhite01 !important;

    &:before
    {
        border-color: $colorWhite01 !important;
    }
    &:after
    {
        border-color: $colorYellow01 !important;
    }

    &.Mui-error:before, &.Mui-error:after
    {
        border-color: $colorRed01 !important;
    }
}

.t2
{
    .MuiFilledInput-root
    {
        background-color: $colorBlack10 !important;

        &:before
        {
            border-color: $colorBlack02 !important;
        }
    }
}

.MuiCheckbox-root
{
    color: $colorWhite01 !important;
}
.MuiFormControlLabel-label
{
    color: $colorWhite01 !important;
    font-size: 14px !important;
}

.MuiLoadingButton-loadingIndicator
{
    color: $colorYellow01 !important;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}
input[type=number]
{
    -moz-appearance: textfield;
}

//--------------------

.page-has-header
{
    padding-top: 44px;
}

.page-has-footer
{
    padding-bottom: 60px;
}