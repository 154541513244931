@import '../../assets/styles/color';

//---------------------------------------------------------

.label-offline
{
    position: absolute;
    top: 12px;
    left: 20px;
    z-index: 1000;

    .MuiChip-icon
    {
        font-size: 20px;
    }
    .MuiChip-label
    {
        font-size: 12px;
    }
}

.remote
{
    background-image: url("../../assets/images/remote-op25.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 262px;
    height: 428px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 72px auto 0 auto;

    .btn-codes
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 48px 12px;
        padding: 0 24px;
        margin-top: 96px;

        .btn
        {
            width: calc(50% - 12px);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $colorWhite01;

            .icon
            {
                font-size: 48px;
            }
        }
    }

    .site-link
    {
        width: 72px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo
        {
            width: 48px;
        }
    }
}