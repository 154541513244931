@import "../../assets/styles/color";

//--------------------

.page-splash
{
    width: 100%;
    min-height: calc(100vh - 48px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //padding: 24px;
    //box-sizing: border-box;

    .logo
    {
        max-width: 100%;
    }

    .title
    {
        color: $colorWhite01;
        text-align: center;
        margin-top: 10px;
        opacity: 0.5;
        position: relative;
    }
}